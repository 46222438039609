/* App.css */
.App {
  text-align: center;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #1a1a1d;  /* Very dark gray, almost black */
  color: #dddddd;  /* Light gray text */
  margin: 0;
  padding: 20px;
}

.App-header {
  background-color: #282c34;  /* Darker background */
  color: #fff;  /* White text color */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;  /* Makes the header take at least the full height of the viewport */
}

input[type="text"] {
  padding: 10px;
  width: 80%;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

button {
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 15px 30px; /* Increased padding for a larger button */
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 18px; /* Larger font size for better readability */
  cursor: pointer;
  border-radius: 5px; /* Slightly larger radius for a softer look */
  transition: transform 0.2s, background-color 0.2s;
}

button:active {
  background-color: #004494; /* Optional: Change color on click */
  transform: translateY(4px); /* Moves the button down to simulate pressing */
}

button:hover {
  background-color: #004494;
}

.next-fact-button {
  width: 100%; /* Make the button take up the full width of its container */
  margin: 10px auto; /* Center the button and add margin */
  align-self: center; /* Center the button */
}

p {
  font-size: 18px;
  line-height: 1.6;
}

/* Add this at the bottom of App.css */
@media (max-width: 768px) {
  input[type="text"], button {
    width: 100%;
    font-size: 14px;
  }

  p {
    font-size: 16px;
  }
}

.fact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Add this line */
  width: 100%;
}

.flashcard-box {  /* Updated class name */
  max-width: 90%; /* Limit the width to 90% of its container */
  width: 90vw; /* Make width responsive based on viewport width */
  margin: 10px auto; /* Center the box and add margin */
  padding: 2vw; /* Responsive padding based on screen width */
  box-sizing: border-box; /* Include padding and border in the width calculation */
  text-align: center; /* Center-align text */
  background-color: #2c3e50; /* Dark background */
  color: white; /* Light text color */
  border-radius: 5px; /* Rounded corners */
  overflow: hidden; /* Prevents children from overflowing */
  word-wrap: break-word; /* Ensures long words do not cause overflow */
}


/* Media Queries for responsive adjustments */
@media (max-width: 300px) {
  .fact-box {
    width: 95%; /* Increase width slightly on smaller screens */
    padding: 4vw; /* Increase padding to maintain proportionality */
    font-size: 4vw; /* Increase font size for readability on small screens */
  }
}

.swipe-container {
  display: flex;
  justify-content: center;
  width: 100%;
  touch-action: pan-y; /* Allow vertical swiping */
}

.carousel .slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 0; /* Hides default arrows */
}

.carousel .control-arrow:hover {
  background: none;
}

/* Hide carousel indicators */
.carousel .control-dots {
  display: none;
}

.topics-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 5px;
}

.topic-button {
  margin: 5px;
  padding: 10px 20px;
  min-width: 100px;
  max-width: 300px;
  flex-grow: 1;
  background-color: #007bff;
  color: white;
  border: 2px solid #ffffff; /* Add a white border */
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px; /* Adjust the font size */
}

.generate-more-button {
  margin-top: 20px;
  background-color: #0056b3; /* Match the color of the Generate Facts button */
}

.generate-more-button:hover {
  background-color: #004494; /* Match the hover color of the Generate Facts button */
}